import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  MenuFilesDisplay,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class MenuPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={null}>
          <Grid
            stackable={true}
            className={"menu-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={"16"}>
              <Header as={"h1"}>Menu</Header>
            </Grid.Column>
            <Grid.Column
              width={"16"}
              textAlign={"left"}
              className={"menu-pattern-container"}
            >
              <Background columns={1}>
                <Background.Pattern type={"CIRCLE"}>
                  <MenuFilesDisplay files={fishermanBusiness.menuFiles} />
                </Background.Pattern>
              </Background>
            </Grid.Column>
            <Grid.Column width={"10"} textAlign={"center"}>
              <h3>&#9832;&#65039; DISCLOSURE*</h3>
              <p>
                1. Regarding the safety of these items, written information is
                available upon request.
              </p>
              <p>
                2. Consuming raw or undercooked MEATS, POULTRY, seafood,
                shellfish, or EGGS may increase your RISK of food-borne illness,
                especially if you have certain medical conditions.
              </p>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      menuFiles {
        url
        filename
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Menu" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
